import { MessageBox } from "@dariosoft/components"
import { DarioHttpClient, THeaders } from "@dariosoft/tools"
import { SessionManager } from "../session-manager"

export abstract class ApiClient {
    protected constructor(private baseAddress: string) {
        this.httpClient = new DarioHttpClient();
    }

    private readonly httpClient: DarioHttpClient;

    private getToken(): string {
        let token = SessionManager.instance.getToken();
        if (!String.isEmpty(token))
            token = 'Bearer ' + token;

        return token;
    }

    private setRequiredHeaders = (headers?: THeaders): THeaders => {
        headers = (isPlainObject(headers) ? headers : {})!;
        headers['Authorization'] = this.getToken();
        headers['client-lang'] = $app.i18n.getCurrent().culture;
        return headers;
    };

    private getRoute(actionUri: string): string {
        if (String.isEmpty(actionUri))
            actionUri = this.baseAddress;
        else if (actionUri.startsWith('~/'))
            actionUri = actionUri.substring(1);
        else
            actionUri = this.baseAddress.trimEndX('/') + '/' + actionUri.trimStartX('/');


        return `${$app.settings.apiUrl.trimEndX('/')}/${actionUri}`;
    }

    private resolveResult<TResult extends IResult>(resp: Response): Promise<TResult> {
        return new Promise<TResult>(resolve => {
            if (resp.ok)
                resp.readAsJson<TResult>()
                    .then(obj => Boolean(obj) ? resolve(obj!) : resolve(Result.fail('Unsupported response!') as TResult))
                    .catch(err => resolve(Result.fail(err) as TResult));
            else {
                resolve(Result.fail({ text: `${resp.status} - ${resp.statusText}`, code: resp.status.toString() }) as TResult);
            }
        }).then(res => {
            if (res.statusCode == 401 || res.errors?.some(x => x.code == '401')) {
                res.errors = [];
                res.warnings = [];
                SessionManager.instance.clear();
                window.location.href = '#/auth';
            }
            return res;
        });
    }

    protected getQueryString(info?: TListLoadModel): string {
        try {
            if (!Boolean(info)) return '';
            let obj: any = { ...info, sortOrder: info!.sortOrder == 'none' ? 0 : (info!.sortOrder == 'asc' ? 1 : 2) };
            if (isPlainObject(info!.params)) {
                let _params: any = info!.params;
                Object.keys(_params).forEach(key => {
                    obj[`$params_${key}`] = _params[key];
                });

            }
            delete obj.params;

            return serializeFlatObjToQueryString(obj);
        }
        catch (err) {
            console.error(err);
        }


        return `page=${info?.pageIndex ?? 0}&pageSize=${info?.pageSize ?? 0}&sort=${info?.sortOrder ?? 0}&sortBy=${info?.sortBy ?? ''}&query=${info?.query ?? ''}`;
    }

    protected http =
        {
            get: <TResult extends IResult>(uri: string, headers?: THeaders) => {
                return this.httpClient.get({ url: this.getRoute(uri), headers: this.setRequiredHeaders(headers) })
                    .then(this.resolveResult<TResult>)
                    // .catch(this.resolveResult<TResult>)
                    .then(MessageBox.toast.show);
            },
            delete: <TResult extends IResult>(uri: string, headers?: THeaders) => {
                return this.httpClient.delete({ url: this.getRoute(uri), headers: this.setRequiredHeaders(headers) })
                    .then(this.resolveResult<TResult>)
                    // .catch(this.resolveResult<TResult>)
                    .then(res => {
                        MessageBox.toast.show(res);
                        return res;
                    });
            },
            post: <TResult extends IResult>(uri: string, headers?: THeaders) => {
                return this.httpClient.post({ url: this.getRoute(uri), headers: this.setRequiredHeaders(headers) })
                    .then(resp => this.resolveResult<TResult>(resp))
                    // .catch(this.resolveResult<TResult>)
                    .then(MessageBox.toast.show);
            },
            put: <TResult extends IResult>(uri: string, headers?: THeaders) => {
                return this.httpClient.put({ url: this.getRoute(uri), headers: this.setRequiredHeaders(headers) })
                    .then(resp => this.resolveResult<TResult>(resp))
                    .catch(this.resolveResult<TResult>)
                    .then(MessageBox.toast.show);
            },
            postJson: <TResult extends IResult>(uri: string, data?: [] | TPlainObject, headers?: THeaders) => {
                return this.httpClient.postJson({ url: this.getRoute(uri), data: data, headers: this.setRequiredHeaders(headers) })
                    .then(resp => this.resolveResult<TResult>(resp))
                    //.catch(this.resolveResult<TResult>)
                    .then(MessageBox.toast.show);
            },
            uploadFile: <TResult extends IResult>(uri: string, data: Blob, headers?: THeaders) => {
                return this.httpClient.uploadFile({ url: this.getRoute(uri), data: data, headers: this.setRequiredHeaders(headers) })
                    .then(this.resolveResult<TResult>)
                    //.catch(this.resolveResult<TResult>)
                    .then(MessageBox.toast.show);
            }
        };
}