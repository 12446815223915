import { TreeView, TTreeNode } from "@dariosoft/components";

export function Home() {
   
    return <div className="vw vw-home">
        <h3>Template Service</h3>
        <p>Author: Pouya Faridi</p>
        <p draggable>Powered by Dariofost - 2024</p>
        <hr />
        <p>Click to see list of&nbsp;<a href="#/clients" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover">Clients</a>.</p>
        <hr />
       
    </div>
}