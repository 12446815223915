export type TTemplateInfo = {
    id: string,
    ownerId: string,
    categoryId: string,
    enabled: boolean,
    path: string,
    lang: string,
    version: string,
    name: string,
    description?: string,
}

export type TTemplateModel = TTemplateInfo & {
    createdAt: string,
}

export type TTemplateContext = {
    templateId: string,
    dataModel: string,
    content: string,
}

export type TTemplateMailTo = {
    templateId: string,
    mailAddress: string,
}

export type TTemplateMoveToCategoryModel = {
    targetCategoryId: string,
    templateIds: string[]
}

export const createDefaultTemplateInfo = (ownerId: string = Guid.emptyId, categoryId: string = Guid.emptyId): TTemplateInfo => ({
    id: '',
    ownerId: Guid.emptyId,
    categoryId: Guid.emptyId,
    enabled: false,
    path: '/en',
    version: 'v1',
    lang: 'en-US',
    name: '',
    description: ''
});