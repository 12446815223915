import { EventService, RouteManager } from '@app/core';
import { useEffect } from 'react';
import { RouterProvider, createHashRouter } from 'react-router-dom';

function App() {
  useEffect(() => {
    EventService.instance.appStarted.dispatch();

    return () => {
      EventService.instance.appStoped.dispatch();
    }
  }, []);

  return <><RouterProvider router={createHashRouter(RouteManager.getRoutes())} /></>;
}

export default App;
