import { useMemo } from 'react';
import './style.scss';

export type TPillBadgeProps = {
    value: string | number | boolean,
    mode: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark',
    rounded?: boolean
}

export function PillBadge(props: TPillBadgeProps) {
    const value = useMemo(() => isBoolean(props.value) ? (props.value === true ? $app.i18n.translates.Yes : $app.i18n.translates.No) : props.value.toString(), [props.value]);

    return <span className={`dariosoft-badge badge text-bg-${props.mode} ${props.rounded ? 'rounded-pill' : ''}`.trim()}>{value}</span>
}