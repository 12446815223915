import './style.scss'
import { Icons, RouteManager } from "@app/core";
import { CategoryService, TemplateService } from "@app/services";
import { categoryTypes, templateTypes } from "@app/types";
import { ActionLink, CardView, Checkbox, Offcanvas, PillBadge, StrSelect, SubLayout, TCardItem, TForm, TSortOption, TTreeNode, TextArea, TextInput, TreeView, offcanvasApi } from "@dariosoft/components";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function ClientTemplates() {
    return <SubLayout layoutRouteMatch='clients/:id/templates'>
        <TemplateList />
    </SubLayout>
}

export function TemplateList() {
    const { clientId } = useParams()
        , editorPaneId = 'editor-pane'
        , service = useMemo(() => new TemplateService(clientId ?? ''), [clientId])
        , categoryService = new CategoryService({
            clientId: clientId ?? Guid.emptyId,
            allowDragOverNode: (e: React.DragEvent<HTMLElement>, target: categoryTypes.TCategoryInfo) => {
                //  let draggingCard = e.currentTarget.closest('.vw')?.querySelector(`.dariosoft-card-view div.card.dragging`);
                //  if (!draggingCard) return false;

                let draggingCardId = getDraggingCardId(e); //draggingCard.getAttribute('data-dragging');

                return !String.isEmpty(draggingCardId) &&
                    service.exsistsTemplate(draggingCardId!)
            },
            onSelectedChanged: (item: categoryTypes.TCategoryInfo) => service.setCurrentCategory(item.id),
            onLoad: (currentSelected: categoryTypes.TCategoryInfo) => service.load(currentSelected.id),
            onDropOver: (e: React.DragEvent<HTMLElement>, target: categoryTypes.TCategoryInfo) => {
                let draggingCardId = getDraggingCardId(e);
                if (!String.isEmpty(draggingCardId))
                    service.moveToCategory(draggingCardId!, target);
            }
        })
        , getDraggingCardId = (e: React.DragEvent<HTMLElement>): string | undefined | null => {
            let draggingCard = e.currentTarget.closest('.vw')?.querySelector(`.dariosoft-card-view div.card.dragging`);
            return draggingCard
                ? draggingCard.getAttribute('data-dragging')
                : undefined;
        }
        , clientInfo = useSelector(service.getClientInfo)
        , autoGeneratePath = useSelector(service.getAutoGeneratePath)
        , pathIsEditable = useSelector(service.getPathIsEditable)
        , isEditMode = useSelector(service.editor.isEditingMode)
        , model = useSelector(service.editor.getModel)
        , loading = useSelector(service.getLoading)
        , addons = <>

            <ActionLink onClick={service.list.loadList} title={$app.i18n.translates.Refresh} css="btn btn-sm btn-outline-dark" icon={Icons.refresh} />&nbsp;
            <ActionLink onClick={() => {
                service.cleanEditor();
                offcanvasApi.show(editorPaneId);
            }
            } title={$app.i18n.translates.Add} css="btn btn-sm btn-outline-dark" icon={Icons.add_Larg} />

            {Boolean(clientInfo) && <div className='client-info'> <b>{$app.i18n.translates.Client}:</b> {clientInfo!.name}</div>}
        </>
        , onEditorShown = () => { tryFocus('#txtName'); }
        , sortOptions: Array<TSortOption> = [
            { field: 'name', label: 'Sort by Name' },
            { field: 'createdAt', label: 'Sort by Creation Time' },
            { field: 'enabled', label: 'Sort by Enable' }
        ],
        carViewFormat = (e: templateTypes.TTemplateModel): TCardItem<templateTypes.TTemplateModel> => ({
            data: e,
            id: e.id,
            header: e.name,
            body: {
                description: e.description,
            },
            list: [
                { label: $app.i18n.translates.CreatedAt, value: e.createdAt },
                { label: $app.i18n.translates.Language, value: e.lang },
                { label: $app.i18n.translates.Version, value: e.version },
                { label: $app.i18n.translates.Path, value: e.path },
                { label: $app.i18n.translates.Enabled, value: <PillBadge value={e.enabled} mode={e.enabled ? 'success' : 'danger'} /> },
            ],
            actions: [
                { icon: Icons.edit, css: 'text-body', tooltip: $app.i18n.translates.Edit, action: e => service.editor.edit(e.data) },
                { icon: `${Icons.trash} text-danger`, css: 'text-body', tooltip: $app.i18n.translates.Delete, action: e => service.list.remove(e.data) },
                { icon: Icons.templateContext, css: 'text-body', tooltip: $app.i18n.translates.Context, link: e => `#clients/${e.data.ownerId}/templates/${e.id}/context` },
                { icon: Icons.copy, css: 'text-body', tooltip: $app.i18n.translates.Clone, action: e => service.clone(e.id) },
                { icon: Icons.preview, css: 'text-body', tooltip: $app.i18n.translates.Preview, linkTarget: '_blank', link: e => service.getPreviewUrl(e.id) },
            ]
        }),
        treeHeader = <>
            <span>{$app.i18n.translates.Folders}&nbsp;</span>
            <a href='#' className='action' onClick={categoryService.refresh.bind(categoryService)}><i className={Icons.refresh}></i></a>
            <a href='#' className='action' onClick={categoryService.addNew.bind(categoryService)}><i className={Icons.add}></i></a>
            <a href='#' className='action' onClick={categoryService.remove.bind(categoryService)}><b><i className={Icons.dash}></i></b></a>
        </>;

    useEffect(() => {
        if (!Guid.isGuid(clientId)) return RouteManager.navigateTo.notFound();
        service.events.onInitizlized(e => {
            if (!Boolean(e.detail.clientInfo) && !loading) return RouteManager.navigateTo.notFound();
        });
        categoryService.load();

        service.events.onBeginEdit(e => { offcanvasApi.show(editorPaneId) });
        service.events.onEditReset(e => { offcanvasApi.hide(editorPaneId) });
        service.events.onAfterSubmit(e => { offcanvasApi.hide(editorPaneId) });

        return () => {

        }
    }, []);


    return <div className='vw vw-template-list'>
        <Offcanvas id={editorPaneId} header={$app.i18n.translates.Editor} onShown={onEditorShown}>
            <TForm id="template-editor" onSubmit={service.editor.submit} onReset={service.editor.reset} lastTabIndex={7} loading={loading} resetButtonVisible={true}>
                <TextInput id="txtName" label={$app.i18n.translates.Name} value={model.name} onChanged={val => service.editor.setModel('name', val ?? '')} maxLength={64} tabIndex={1} autoFocus />
                <StrSelect id="txtLang" label={$app.i18n.translates.Language} items={service.languages} value={model.lang} onChanged={e => service.editor.setModel('lang', e as any)} tabIndex={2} />
                <TextInput id="txtVersion" label={$app.i18n.translates.Version} value={model.version} onChanged={val => service.editor.setModel('version', val || '')} maxLength={10} tabIndex={3} />
                {!isEditMode && <Checkbox id='chkAutoGeneratePath' label={$app.i18n.translates.AutoGeneratePath} value={autoGeneratePath} onChanged={service.setAutoGeneratePath} tabIndex={4} />}
                <TextInput id="txtPath" label={$app.i18n.translates.Path} value={model.path} onChanged={val => service.editor.setModel('path', val || '')} readonly={!pathIsEditable} maxLength={255} tabIndex={5} />
                <TextArea id="txtDescription" label={$app.i18n.translates.Description} value={model.description} onChanged={val => service.editor.setModel('description', val)} maxLength={255} tabIndex={6} optional />
                <Checkbox id="chkEnabled" label={$app.i18n.translates.Active} value={model.enabled} onChanged={val => service.editor.setModel('enabled', val)} tabIndex={7} />
            </TForm>
        </Offcanvas>
        <div className='content-section'>
            <div className='col-2'>
                <TreeView service={categoryService}
                    header={treeHeader}
                />
            </div>
            <div className='col-10'>
                <CardView
                    draggable={true}
                    rowCols={{ md: 3, xxl: 4 }}
                    context={service.list}
                    format={carViewFormat}
                    searchBoxVisible={true}
                    sortOptions={sortOptions}
                    extensions={addons}
                />
            </div>
        </div>

    </div>
}