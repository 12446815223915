export type LTreeSeparator = '.' | '/';

export class LTree {
    private constructor(value: string, separator: LTreeSeparator) {
        this.separator = separator;
        this.value = value.split(separator).filter(e => !String.isEmpty(e)).join(separator);

        this.level = this.value.charCount(separator);
        this.depth = this.level + 1;
        this.parent = this.getAncestor(1);
        this.sameParentCheckRegexPattern = String.isEmpty(this.parent)
            ? '^\d+$'
            : `^${this.parent.replace(separator, `\\${separator}`)}\\${separator}\d+$`;

    }

    public readonly separator: LTreeSeparator;
    public readonly parent: string | null | undefined;
    public readonly value: string;
    public readonly level: number;
    public readonly depth: number;
    public readonly sameParentCheckRegexPattern: string;

    public isDescendantsOf(ancestor: string): boolean {
        ancestor = (ancestor ?? '').trimX(this.separator);
        if (String.isEmpty(ancestor))
            return false;

        ancestor += this.separator;

        return this.value.startsWith(ancestor);
    }

    public isAncestor(descendant: string): boolean {
        descendant = (descendant ?? '').trimX(this.separator);
        return (descendant ?? '').startsWith(this.value + this.separator);
    }

    public getAncestor(n: number): string {
        if (n < 1) return this.value;
        return this.value
            .split(this.separator)
            .slice(0, this.depth - n)
            .join(this.separator);
    }

    public newChild(child: string | number): string {
        child = (child || '').toString().trimX(this.separator);
        return String.isEmpty(this.value)
            ? child
            : `${this.value}${this.separator}${child}`;
    }

    public static parse(value: string, separator: LTreeSeparator = '.'): LTree {
        return new LTree(value ?? '', separator ?? '.');
    }

    public static isAncestor(ancestor: string, child: string, separator: LTreeSeparator = '.'): boolean {
        if (String.isEmpty(separator)) separator = '.';
        ancestor = ancestor.trimEndX(separator) + separator;
        return child != ancestor && child.startsWith(ancestor);
    }

    public static changeParent(path: string, newParent: string, separator: LTreeSeparator = '.'): string {
        if (String.isEmpty(separator)) separator = '.';
        if (String.isEmpty(path) || path.indexOf(separator) == -1) return path;

         return newParent + path.substring(path.lastIndexOf(separator));
    }

    public toString(): string {
        return this.value;
    }


}