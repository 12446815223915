import { createAsyncThunk } from "@reduxjs/toolkit";
import { categoryTypes as types } from "@app/types";
import { ApiClient } from "./___ApiClient";
import { StoreManager } from "@app/core";
import { TApiInput } from '@dariosoft/tools'

export class CategoryApi extends ApiClient {
    private constructor() {
        super('admin/api/v1/category')
    }

    //#region PUBLICS
    public static get instance(): CategoryApi {
        if (CategoryApi._instance == null) CategoryApi._instance = new CategoryApi();
        return CategoryApi._instance!;
    }

    public append = {
        thunk: createAsyncThunk('admin/category/append', (parentId: string) => this.api.append(parentId)),
        submit: (parentId: string) => StoreManager.dispatch(this.append.thunk(parentId) as any)
    }

    public create = {
        thunk: createAsyncThunk('admin/category/create', (payload: TApiInput<types.TCategoryInfo, {}>) => this.api.create(payload.data)),
        submit: (payload: TApiInput<types.TCategoryInfo, {}>) => StoreManager.dispatch(this.create.thunk(payload) as any)
    }

    public update = {
        thunk: createAsyncThunk('admin/category/update', (payload: TApiInput<types.TCategoryInfo, {}>) => this.api.update(payload.data)),
        submit: (payload: TApiInput<types.TCategoryInfo, {}>) => StoreManager.dispatch(this.update.thunk(payload) as any)
    }

    public delete = {
        thunk: createAsyncThunk('admin/category/delete', (payload: TApiInput<types.TCategoryModel, {}>) => this.api.delete(payload.data.id)),
        submit: (payload: TApiInput<types.TCategoryModel, {}>) => StoreManager.dispatch(this.delete.thunk(payload) as any)
    }

    public permanentDelete = {
        thunk: createAsyncThunk('admin/category/delete/permanent', (payload: TApiInput<types.TCategoryModel, {}>) => this.api.permanentDelete(payload.data.id)),
        submit: (payload: TApiInput<types.TCategoryModel, {}>) => StoreManager.dispatch(this.permanentDelete.thunk(payload) as any)
    }

    public get = {
        thunk: createAsyncThunk('admin/category/get', (id: string) => this.api.get(id)),
        submit: (id: string) => StoreManager.dispatch(this.get.thunk(id) as any)
    }

    public move = {
        thunk: createAsyncThunk('admin/category/move', (payload: { srcId: string, destId: string }) => this.api.move(payload)),
        submit: (payload: { srcId: string, destId: string }) => StoreManager.dispatch(this.move.thunk(payload) as any)
    }

    public list = {
        thunk: createAsyncThunk('admin/category/list', (payload: TApiInput<TListLoadModel<{ clientId: string }>, {}>) => this.api.list(payload.data)),
        submit: (payload: TApiInput<TListLoadModel<{ clientId: string }>, {}>) => StoreManager.dispatch(this.list.thunk(payload) as any)
    }
    //#endregion

    //#region PRIVATES
    private static _instance: CategoryApi | null = null;
    private readonly api = {
        append: (parentId: string): Promise<IResult<TSubmitResultDto>> => this.http.postJson(`append/${parentId}`),
        create: (payload: types.TCategoryInfo): Promise<IResult<TSubmitResultDto>> => this.http.postJson(`create`, payload),
        update: (payload: types.TCategoryInfo): Promise<IResult> => this.http.postJson(`update`, payload),
        delete: (id: string): Promise<IResult> => this.http.delete(`delete/${id}`),
        permanentDelete: (id: string): Promise<IResult> => this.http.delete(`delete/${id}/permanent`),
        get: (id: string): Promise<IResult<types.TCategoryModel>> => this.http.get(`get/${id}/by-id`),
        move: (payload: { srcId: string, destId: string }): Promise<IResult<types.TCategoryModel>> => this.http.post(`move/${payload.srcId}/to/${payload.destId}`),
        list: (model: TListLoadModel<{ clientId: string }>): Promise<IListResult<types.TCategoryModel>> => {
            let clientId = model.params.clientId;
            let payload = { ...model, params: { ...model.params, clientId: undefined } };

            return this.http.get<IListResult<types.TCategoryModel>>(`list/${clientId}?${super.getQueryString(payload)}`)
        },
    }
    //#endregion
}