import './style.scss'
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type TInputProps = {
    id: string,
    name?: string,
    label?: string,
    description?: string,
    placeholder?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    disabled?: boolean,
    optional?: boolean,
    readonly?: boolean,
    pattern?: string,
    minLength?: number,
    maxLength?: number,
    errors?: string[],
    fillOutRequiredFieldMessage?: string,
    invalidValueMessage?: string,
    dataListId?: string,
    onInvalid?: (value: string | null | undefined, setError: (message: string) => void) => void,
}
export type TTextInputProps = TInputProps & {
    type?: 'text' | 'email' | 'password' | 'date',
    value?: string | null,
    keyboardInputControlPatterns?: RegExp[],
    onChanged?: (value?: string, e?: React.ChangeEvent<HTMLInputElement>) => void
};

export type TNumberInputProps = TInputProps & {
    floatingPoint?: boolean,
    value?: number | null,
    step?: number | string,
    onChanged?: (value: number, e?: React.ChangeEvent<HTMLInputElement>) => void
};

export function TextInput(props: TTextInputProps) {
    return LabeledEditBase(props as TLabeledEditProps);
};

export function NumberInput(props: TNumberInputProps) {
    return LabeledEditBase({ ...props, type: 'number', keyboardInputControlPatterns: [/\d/, /\./] } as TLabeledEditProps);
};

type TLabeledEditProps = TInputProps & {
    type: 'text' | 'password' | 'email' | 'date' | 'number',
    value?: any,
    step?: number | string,
    floatingPoint?: boolean,
    keyboardInputControlPatterns?: RegExp[],
    onChanged?: (value?: string | number | Date, e?: React.ChangeEvent<HTMLInputElement>) => void
};

const isValid = (patterns: RegExp[] | undefined | null, key: string, control: boolean) => {
    if (patterns == null ||
        patterns == undefined ||
        patterns.length == 0 ||
        /ArrowLeft|ArrowRight|Home|End|Tab|Backspace|Delete/i.test(key) ||
        (control && /z|y|c|v/i.test(key)))
        return true;
    return patterns.every(p => p.test(key));
},
    LabeledEditBase = function (props: TLabeledEditProps) {

        const componentId = useMemo(() => `labeled-edit-${Guid.newId()}`, [])
            , name = useMemo(() => String.isEmpty(props.name) ? props.id : props.name, [props.id, props.name])
            , fillOutRequiredFieldMessage = useMemo(() => String.isEmpty(props.fillOutRequiredFieldMessage) ? $app.i18n.translates.MSG_Warn_FillOutField : props.fillOutRequiredFieldMessage!, [props.fillOutRequiredFieldMessage])
            , invalidValueMessage = useMemo(() => String.isEmpty(props.fillOutRequiredFieldMessage) ? $app.i18n.translates.MSG_Warn_InvalidValue : props.fillOutRequiredFieldMessage!, [props.invalidValueMessage])
            , keyIsValid = useCallback(isValid.bind({}, props.keyboardInputControlPatterns), [props.keyboardInputControlPatterns])
            , onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (!keyIsValid(e.key, e.ctrlKey)) {
                    e.stopPropagation();
                    e.preventDefault();
                }
            }
            , onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                if (props.onChanged && props.onChanged instanceof Function) {
                    let value: any = e.target.value;
                    if (props.type == 'number') {
                        value = +value;
                        value = isNaN(value) ? 0 : value;
                    }

                    props.onChanged(value, e)
                }
            }
            , onInput = (e: React.FormEvent<HTMLInputElement>) => { (e.target as HTMLInputElement).setCustomValidity(''); }
            , onInvalid = (e: React.FormEvent<HTMLInputElement>) => {
                let input = (e.target as HTMLInputElement);
                input.setCustomValidity(
                    String.isEmpty(input.value)
                        ? fillOutRequiredFieldMessage
                        : (String.isEmpty(input.validationMessage) ? invalidValueMessage : input.validationMessage)
                );
            }
            , [passVisible, setPassVisible] = useState(false)
            , inputType = useMemo(() => {
                if (props.type == 'password') {
                    return passVisible ? 'text' : 'password';
                }
                return props.type;
            }, [passVisible, props.type])
            , togglePassVisible = (e: React.MouseEvent) => {
                e.preventDefault();
                setPassVisible(!passVisible);
            }

        useEffect(() => {
            let errors = props.errors?.filter(x => !String.isEmpty(x));
            let input = document.querySelector<HTMLInputElement>(`#${componentId} input`)!;

            let msg = (!errors || errors.length < 1)
                ? ''
                : errors.length == 1 ? errors[0] : errors.map((e, i) => `${i + 1}. ${e}`).join('\n');

            input.setCustomValidity(msg);
        }, [props.errors]);

        return <div className="mb-3 form-input-element labeled-input" id={componentId}>
            {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
            {!String.isEmpty(props.description) && <p>{props.description}</p>}
            <div className='input-area'>
                <input type={inputType}
                    onInvalid={onInvalid}
                    onInput={onInput}
                    id={props.id}
                    name={name}
                    value={props.value?.toString()}
                    onKeyDown={onKeyDown}
                    onChange={onChange}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    className="form-control form-control-sm"
                    required={!Boolean(props.optional)}
                    tabIndex={props.tabIndex}
                    maxLength={props.maxLength}
                    step={props.step}
                    list={props.dataListId}
                    pattern={props.pattern}
                    placeholder={props.placeholder}
                    readOnly={props.readonly}
                />
                {props.type == 'password' && <a href='#' className='btn-toggle-passvisible' onClick={togglePassVisible}><i className={passVisible ? 'bi bi-eye-slash' : 'bi bi-eye'}></i></a>}
            </div>
        </div>
    }