import React, { useMemo } from "react";
export type TFormValidator = { [key: string]: (val: string) => string[] };

export type TFormProps = {
    id: string,
    loading?: boolean,
    noSubmitBtn?: boolean,
    lastTabIndex?: number,
    submitText?: string,
    resetText?: string,
    submitCss?: string,
    children?: React.ReactNode,
    otherButtons?: React.ReactNode,
    resetButtonVisible?: boolean | (() => boolean),
    validator?: TFormValidator,
    onSubmit?: () => void
    onReset?: () => void
};

export const TForm = (props: TFormProps) => {

    const getEffectiveValue = (el: HTMLElement): string => {
        let valueNow = el.getAttribute('aria-aria-valuenow'), val = el.getAttribute('value');
        return (String.isEmpty(valueNow) ? val : valueNow) ?? '';
    }
        , onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (props.validator) {
                let form: HTMLFormElement = e.target as any;
                let keys = Object.keys(props.validator);
                let hasError = false;
                for (let i = 0; i <= keys.length; i++) {
                    let input: any = form.querySelector(`#${keys[i]}`);
                    if (input && input.setCustomValidity instanceof Function) {
                        input.setCustomValidity('');

                        let errors = props.validator[keys[i]](getEffectiveValue(input));
                        if (errors && errors.length > 0) {
                            let msg = errors.length == 1 ? errors[0] : errors.map((x, i) => `${i + 1}. ${x}`).join('\n');
                            input.setCustomValidity(msg);
                            form.reportValidity();
                            hasError = true;
                            break;
                        }
                    }
                }

                if (hasError) return false;
            }
            !props.loading && props.onSubmit instanceof Function && props.onSubmit()
        },
        onReset = (e: React.FormEvent<HTMLFormElement>) => { props.onReset instanceof Function && props.onReset() },
        resetBtnVisible = (): boolean => {
            return (props.resetButtonVisible instanceof Function && props.resetButtonVisible())
                || Boolean(props.resetButtonVisible);
        },
        submitText = useMemo(() => String.isEmpty(props.submitText) ? $app.i18n.translates.Save : props.submitText, [props.submitText]),
        resetText = useMemo(() => String.isEmpty(props.resetText) ? $app.i18n.translates.Cancel : props.resetText, [props.resetText])

    return <form id={props.id ?? ''} onSubmit={onSubmit} onReset={onReset} className={`${props.loading ? 'loading-from' : ''}`.trim()}>
        {props.children}
        {(!Boolean(props.noSubmitBtn) || resetBtnVisible() || props.otherButtons) && <div className='mt-3'>
            {!Boolean(props.noSubmitBtn) && <button className={`${String.isEmpty(props.submitCss) ? 'btn btn-sm btn-primary' : props.submitCss}`} type='submit' disabled={props.loading} tabIndex={(props.lastTabIndex ?? 0) + 1}>{submitText}</button>}
            {resetBtnVisible() && <>&nbsp;<button className="btn btn-sm btn-secondary" type="reset" tabIndex={(props.lastTabIndex ?? 0) + 2} >{resetText}</button></>}
            {props.otherButtons && <>&nbsp;{props.otherButtons}</>}
        </div>}
    </form>
};