export const LangSelect = function () {
    const lang = $app.i18n.getCurrent().title;


    return <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {lang}
        </a>
        <ul className="dropdown-menu langs">
            {$app.i18n.cultures.map((x, i) => <li key={`lang-item-${x.name}`}><a className="dropdown-item" href={$app.i18n.buildUrl(x)}>{x.title}</a></li>)}
        </ul>
    </li>
}