import { DarioModal } from './modal';

export type MessageBoxOptions = { title?: string, timeout: number };

export const MessageBox = (function () {
    let win: any = window;

    const context = win.MessageBox = {
        toast: {
            error: (message: string, options?: MessageBoxOptions) => win.$.toast({message: message, title: options?.title ?? $app.i18n.translates.Error, duration: options?.timeout ?? 5000, type: 'error' }), //win.toastr["error"](message),
            info: (message: string, options?: MessageBoxOptions) => win.$.toast({message: message, title: options?.title, duration: options?.timeout ?? 5000, type: 'info' }), //win.toastr["info"](message),
            success: (message: string, options?: MessageBoxOptions) => win.$.toast({message: message, title: options?.title, duration: options?.timeout ?? 5000, type: 'success' }), //win.toastr["success"](message),
            warn: (message: string, options?: MessageBoxOptions) => win.$.toast({message: message, title: options?.title, duration: options?.timeout ?? 5000, type: 'warning' }), //win.toastr["warning"](message),
            submitSuccessfull: () => context.toast.success($app.i18n.translates.MSG_Success_Info),
            fileUploadSuccessfull: () => context.toast.success($app.i18n.translates.MSG_Success_Upload),
            deleteSuccessfull: () => context.toast.success($app.i18n.translates.MSG_Success_Delete),
            warnLargeFile: (filename: string, maxSize: string) => context.toast.warn(($app.i18n.translates.MSG_Warn_TooLargeFile ?? '').replace('{:file-name}', filename).replace('{:file-size}', maxSize)),
            warnNotSamePasswords: (options?: MessageBoxOptions) => context.toast.warn($app.i18n.translates.MSG_Warn_NewPasswordAndConfirmPasswordAreNotSame),
            passwordUpdatedSuccessfully: () => context.toast.success($app.i18n.translates.MSG_Success_PasswordChanged),
            show: <TResult extends IResult>(result: TResult, options?: MessageBoxOptions): TResult => {
                if (result.errors && result.errors.length > 0) {
                    for (let i = 0; i < result.errors.length; i++) {
                        context.toast.error(result.errors[i].text);
                    }
                }
                if (result.warnings && result.warnings.length > 0) {
                    for (let i = 0; i < result.warnings.length; i++) {
                        context.toast.warn(result.warnings[i].text);
                    }
                }

                return result;
            }
        },
        modal: {
            confirmDelete: (description?: string): Promise<boolean> => {
                description = String.isEmpty(description) ? '' : ` "${description!.trim()}"`;

                return DarioModal.open({
                    title: $app.i18n.translates.ConfirmDelete,
                    content: $app.i18n.translates.MSG_Confirm_ConfirmDelete.replace('@Description', description),
                    buttons: [
                        { key: 'accept', css: 'btn btn-sm btn-primary', text: $app.i18n.translates.Yes },
                        { key: 'reject', css: 'btn btn-sm btn-secondary', text: $app.i18n.translates.No }
                    ]
                }).then(res => res === 'accept');

            },
            confirmCancelOrder: (product?: string): Promise<boolean> => {
                product = String.isEmpty(product) ? '' : ` "${product!.trim()}"`;

                return DarioModal.open({
                    title: $app.i18n.translates.ConfirmDelete,
                    content: $app.i18n.translates.MSG_Confirm_Cancel_Order.replace('@Description', product),
                    buttons: [
                        { key: 'accept', css: 'btn btn-sm btn-primary', text: $app.i18n.translates.Yes },
                        { key: 'reject', css: 'btn btn-sm btn-secondary', text: $app.i18n.translates.No }
                    ]
                }).then(res => res === 'accept');

            },
            confirmSave: (): Promise<boolean> => {
                return DarioModal.open({
                    title: $app.i18n.translates.Confirm,
                    content: $app.i18n.translates.MSG_Confirm_ConfirmSave,
                    buttons: [
                        { key: 'accept', css: 'btn btn-sm btn-primary', text: $app.i18n.translates.Yes },
                        { key: 'reject', css: 'btn btn-sm btn-secondary', text: $app.i18n.translates.No }
                    ]
                }).then(res => res === 'accept');

            },
            confirmProceedOperation: (): Promise<boolean> => {
                return DarioModal.open({
                    title: $app.i18n.translates.Confirm,
                    content: $app.i18n.translates.MSG_Confirm_Proceed_Operation,
                    buttons: [
                        { key: 'accept', css: 'btn btn-sm btn-primary', text: $app.i18n.translates.Yes },
                        { key: 'reject', css: 'btn btn-sm btn-secondary', text: $app.i18n.translates.No }
                    ]
                }).then(res => res === 'accept');

            }
        }
    };

    return context;
})();
