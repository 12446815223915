import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager } from "../store-manager";
import { ForgotPasswordApi } from "@app/api";
import { helpers } from "@app/core";

export type TForgotPasswordStep = 'Start' | 'Verify' | 'Reset' | 'Finish';

type TModel = {
    usernameOrEmail: string,
    code: string,
    password: string,
    confirmPassword: string,
}

type TModelKeys = keyof TModel;


type TState = {
    loading: boolean,
    step: TForgotPasswordStep,
    token: string,
    model: TModel
}

type TDraft = Draft<TState>;

type TReducers = {
    setModel: <T extends TModelKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TModel[T] }>) => void
    setStep: (state: TDraft, action: PayloadAction<TForgotPasswordStep>) => void,
    init: (state: TDraft) => void,
}

export class ForgotPasswordService {
    private constructor() {
        const [slice, getState] = StoreManager.createSlice<TState, TReducers>({
            name: 'services/forgot-password',
            initialState: {
                loading: false,
                step: 'Start',
                token: '',
                model: {
                    usernameOrEmail: '',
                    code: '',
                    password: '',
                    confirmPassword: ''
                }
            },
            reducers: {
                setModel: <T extends TModelKeys>(state: TDraft, action: PayloadAction<{ key: T, value: TModel[T] }>) => {
                    state.model[action.payload.key] = action.payload.value;
                },
                setStep: (state: TDraft, action: PayloadAction<TForgotPasswordStep>) => {
                    state.step = action.payload;
                },
                init: (state: TDraft) => {
                    state.step = 'Start';
                    state.token = '';
                    state.model.code = '';
                    state.model.usernameOrEmail = '';
                    state.model.password = '';
                    state.model.confirmPassword = '';
                }
            },
            extraReducers: builder => {
                builder.addCase(ForgotPasswordApi.instance.start.thunk.pending, state => {
                    state.step = 'Start';
                    state.loading = true;
                }).addCase(ForgotPasswordApi.instance.start.thunk.rejected, state => {
                    state.step = 'Start';
                    state.loading = false;
                }).addCase(ForgotPasswordApi.instance.start.thunk.fulfilled, (state, action) => {
                    state.loading = false;
                    if (action.payload.isSuccessful && isPlainObject(action.payload.data)) {
                        state.token = action.payload.data!.token;
                        state.step = 'Verify';
                    }
                })
                    //========================
                    .addCase(ForgotPasswordApi.instance.verify.thunk.pending, state => {
                        state.step = 'Verify';
                        state.loading = true;
                    }).addCase(ForgotPasswordApi.instance.verify.thunk.rejected, state => {
                        state.step = 'Verify';
                        state.loading = false;
                    }).addCase(ForgotPasswordApi.instance.verify.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful)
                            state.step = 'Reset';

                    })
                    //========================
                    .addCase(ForgotPasswordApi.instance.resetPassword.thunk.pending, state => {
                        state.step = 'Reset';
                        state.loading = true;
                    }).addCase(ForgotPasswordApi.instance.resetPassword.thunk.rejected, state => {
                        state.step = 'Reset';
                        state.loading = false;
                    }).addCase(ForgotPasswordApi.instance.resetPassword.thunk.fulfilled, (state, action) => {
                        state.loading = false;
                        if (action.payload.isSuccessful) {
                            state.step = 'Finish';
                            state.token = '';
                            state.model.code = '';
                            state.model.usernameOrEmail = '';
                            state.model.password = '';
                            state.model.confirmPassword = '';
                        }

                    })
            }
        });

        this.slice = slice;
        this.getState = getState;
    }

    //#region --- publics ---
    public getLoading = (): boolean => this.getState().loading;
    public getStep = (): TForgotPasswordStep => this.getState().step;
    public getModel = (): TModel => this.getState().model;
    public setModel = <T extends TModelKeys>(key: T, value: TModel[T]): void => {
        if (this.getModel()[key] == value) return;
        StoreManager.dispatch(this.slice.actions.setModel({ key: key, value: value.trim() }));
    }

    public init = (): void => {
        StoreManager.dispatch(this.slice.actions.init());
    }

    public start = (): void => {
        let state = this.getState();
        if (state.step != 'Start') return;
        ForgotPasswordApi.instance.start.submit({ usernameOrEmail: state.model.usernameOrEmail });
    }

    public verify = (): void => {
        let state = this.getState();
        if (state.step != 'Verify') return;
        ForgotPasswordApi.instance.verify.submit({
            usernameOrEmail: state.model.usernameOrEmail,
            token: state.token,
            code: state.model.code
        });
    }

    public resetPassword = async (): Promise<void> => {
        let state = this.getState();
        if (state.step != 'Reset') return;
        let model = {
            token: state.token,
            usernameOrEmail: state.model.usernameOrEmail,
            password: state.model.password,
            confirmPassword: state.model.confirmPassword
        };
        model.password = await darioCrypto.lock(model.password);
        model.confirmPassword = await darioCrypto.lock(model.confirmPassword);

        ForgotPasswordApi.instance.resetPassword.submit(model);
    }

    public getPsswordErrors = (): string[] => {
        let password = this.getModel().password;
        return helpers.securityHelper.checkPasswordComplexity(password)
            ? []
            : [$app.i18n.translates.TooSimplePassword]
    }

    public getConfirmPsswordErrors = (): string[] => {
        let state = this.getState();
        return state.model.password === state.model.confirmPassword
            ? []
            : [$app.i18n.translates.MismatchConfirmPassword];
    }

    public back = (): void => {
        let currentStep = this.getStep();
        if (currentStep == 'Verify') currentStep = 'Start';
        if (currentStep == 'Reset') currentStep = 'Verify';
        StoreManager.dispatch(this.slice.actions.setStep(currentStep));
    }

    public static get instance(): ForgotPasswordService {
        if (ForgotPasswordService._instance == null)
            ForgotPasswordService._instance = new ForgotPasswordService();

        return ForgotPasswordService._instance!;
    }
    //#endregion

    //#region privates
    private static _instance: ForgotPasswordService | null = null;
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TDraft, TReducers>;
    //#endregion
}