import './style.scss'
import { ForgotPasswordService } from "@app/services";
import { TForm, TextInput } from "@dariosoft/components";
import { useEffect } from 'react';
import { useSelector } from "react-redux";

export function ForgotPassword() {
    const service = ForgotPasswordService.instance,
        model = useSelector(service.getModel),
        loading = useSelector(service.getLoading),
        step = useSelector(service.getStep),
        passwordErrors = useSelector(service.getPsswordErrors),
        confirmPasswordErrors = useSelector(service.getConfirmPsswordErrors),
        otherButtons = <>
            <a className="btn btn-sm btn-secondary" href="#/auth/">{$app.i18n.translates.Cancel}</a>
        </>,
        backbtn = <>
            <button onClick={service.back} className="btn btn-sm btn-primary">{$app.i18n.translates.Back}</button>
        </>

    useEffect(() => {
        service.init();
    }, []);
    return <div className="vw-forgot-password">
        {step == 'Start' && <TForm id="from-start" onSubmit={service.start} loading={loading} submitText={$app.i18n.translates.Submit} lastTabIndex={1} otherButtons={otherButtons}>
            <TextInput label={$app.i18n.translates.UserNameOrEmail} id="txtUserName" value={model.usernameOrEmail} onChanged={val => service.setModel('usernameOrEmail', val ?? '')} autoFocus tabIndex={1} maxLength={128} />
        </TForm>}
        {
            step == 'Verify' && <TForm id="form-verify" onSubmit={service.verify} loading={loading} submitText={$app.i18n.translates.Submit} lastTabIndex={1} otherButtons={backbtn}>
                <TextInput label={$app.i18n.translates.UserNameOrEmail} id="txtUserName" value={model.usernameOrEmail} autoFocus readonly />
                <TextInput label={$app.i18n.translates.VerificationCode} id="txtVerifyCode" value={model.code} onChanged={val => service.setModel('code', val ?? '')} autoFocus tabIndex={1} maxLength={10} />
            </TForm>
        }
        {
            step == 'Reset' && <TForm id="form-reset" onSubmit={service.resetPassword} loading={loading} submitText={$app.i18n.translates.Submit} lastTabIndex={1} otherButtons={backbtn}>
                <TextInput label={$app.i18n.translates.UserNameOrEmail} id="txtUserName" value={model.usernameOrEmail} autoFocus readonly />
                <TextInput label={$app.i18n.translates.NewPassword} id="txtNewPassword" type="password" errors={passwordErrors} value={model.password} onChanged={val => service.setModel('password', val ?? '')} autoFocus tabIndex={1} maxLength={64} />
                <TextInput label={$app.i18n.translates.ConfirmPassword} id="txtConfirmPassword" type="password" errors={confirmPasswordErrors} value={model.confirmPassword} onChanged={val => service.setModel('confirmPassword', val ?? '')} tabIndex={2} maxLength={64} />
            </TForm>
        }
        {
            step == 'Finish' && <div className="finish">
                <i className="bi bi-check2-circle"></i>
                <h6>{$app.i18n.translates.MSG_Success_PasswordChanged}</h6>
                <a className='btn btn-sm btn-outline-primary' href="#/auth/">{$app.i18n.translates.Login}</a>
            </div>
        }
    </div>
}