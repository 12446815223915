import React, { useEffect, useMemo } from "react"


export type TOffcanvasProps = {
    id: string,
    title?: JSX.Element,
    showCloseButton?: boolean,
    header?: React.ReactNode,
    children?: React.ReactElement,
    position?: 'start' | 'end' | 'top' | 'bottom',
    onShow?: (e: Event) => void,
    onShown?: (e: Event) => void,
    onClosing?: (e: Event) => void,
    onClosed?: (e: Event) => void,
}

export function Offcanvas(props: TOffcanvasProps) {
    const direction = useMemo(() => {
     
        return String.isEmpty(props.position) 
            ? $app.i18n.getCurrent().rtl ? 'start' : 'end'
            : props.position;
    }, [props.position]);

    useEffect(() => {
        let element = window.document.getElementById(props.id);
        if (element) {
            if (props.onShow) element.addEventListener('show.bs.offcanvas', props.onShow);
            if (props.onShown) element.addEventListener('shown.bs.offcanvas', props.onShown);
            if (props.onClosing) element.addEventListener('hide.bs.offcanvas', props.onClosing);
            if (props.onClosed) element.addEventListener('hidden.bs.offcanvas', props.onClosed);
        }
    }, []);

    return <div className={`offcanvas offcanvas-${direction}`} id={props.id} data-bs-keyboard="true">
        <Title showCloseButton={props.showCloseButton} header={props.header} />
        <div className="offcanvas-body">
            {props.children}
        </div>
    </div>
}

export const offcanvasApi = (function () {
    type TOffcanvasApi = {
        addEventListener: (name: string, handler: Function) => void,
        show: () => void,
        hide: () => void,
        _isShown: boolean
    }

    const win = (window as any),
        context: { [key: string]: TOffcanvasApi } = {},
        getInstance = (id: string): TOffcanvasApi | null => {
            if (!win.bootstrap) {
                console.warn(`Bootstap is not installed.`);
                return null;
            }

            let element = window.document.getElementById(id);
            if (!element) {
                console.warn(`There is not element with id "${id}".`);
                return null;
            }
            return win.bootstrap.Offcanvas.getOrCreateInstance(element) as TOffcanvasApi;

        };


    return Object.freeze({
        show: (id: string) => {
            let instance = getInstance(id);
            if (!instance) return;
            instance.show();
        },
        hide: (id: string) => {
            let instance = getInstance(id);
            if (!instance) return;
            instance.hide();
        },
    });

})();

const Title = function (props: { showCloseButton?: boolean, header?: React.ReactNode }) {
    let showCloseButton = props.showCloseButton === undefined || Boolean(props.showCloseButton);

    if (!showCloseButton && !Boolean(props.header)) return <></>;

    return <div className="offcanvas-header">
        <h5 className="offcanvas-title">{props.header}</h5>
        {showCloseButton && <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>}
    </div>
}