import { SettingsService } from '@app/services'
import './style.scss'
import { useSelector } from 'react-redux'
import { Checkbox, StrSelect, TForm, TextInput } from '@dariosoft/components';
import { useEffect, useMemo } from 'react';
export function SmtpSettings() {
    const service = SettingsService.instance.smtp,
        loading = useSelector(SettingsService.instance.getLoading),
        tlsVersions = useSelector(service.getTLSVersions),
        model = useSelector(service.getModel),
        port = useMemo(() => !Boolean(model.portNumber) || model.portNumber == 0 ? '' : model.portNumber.toString(), [model.portNumber]),
        otherButtons = <>
            <button onClick={service.load} className='btn btn-sm btn-primary' type='button'>{$app.i18n.translates.Refresh}</button>
        </>
        ;

    useEffect(() => {
        setTimeout(service.load, 50);
    }, []);

    return <div className="vw vw-smtp-settings">
        <TForm id='formSmtpSettings' onSubmit={service.update} otherButtons={otherButtons} loading={loading} submitText={$app.i18n.translates.Update} lastTabIndex={12}>
            <fieldset className='mb-3'>
                <legend>{$app.i18n.translates.MailServer}</legend>
                <TextInput id='txtHostAddress' label={$app.i18n.translates.HostAddress} value={model.hostAddress} onChanged={val => service.setModel('hostAddress', val ?? '')} tabIndex={1} autoFocus />
                <TextInput id='txtPortNumber' label={$app.i18n.translates.PortNumber} value={port} keyboardInputControlPatterns={[/\d+/]} onChanged={val => service.setModel('portNumber', Number.parse(val))} tabIndex={2} />
                <Checkbox id='chkSecure' label={$app.i18n.translates.Secure} value={model.secure} onChanged={val => service.setModel('secure', val)} tabIndex={3} />
                <hr />
                <fieldset>
                    <legend>{$app.i18n.translates.TLS_Settings} </legend>
                    <Checkbox id='chkRejectUnauthorized' label={$app.i18n.translates.RejectUnauthorized} value={Boolean(model.tls?.rejectUnauthorized)} onChanged={val => service.setModelTLS('rejectUnauthorized', val)} tabIndex={4} />
                    <TextInput id='txtCiphers' label={$app.i18n.translates.Ciphers} value={model.tls?.ciphers ?? ''} onChanged={val => service.setModelTLS('ciphers', val)} optional tabIndex={5} />
                    <StrSelect id="txtMinVersion" label={$app.i18n.translates.MinVersion} value={model.tls?.minVersion ?? ''} onChanged={val => service.setModelTLS('minVersion', val as any) } items={tlsVersions} showUnknownItem tabIndex={6} optional />
                    <StrSelect id="txtMaxVersion" label={$app.i18n.translates.MaxVersion} value={model.tls?.maxVersion ?? ''} onChanged={val => service.setModelTLS('maxVersion', val as any) } items={tlsVersions} showUnknownItem tabIndex={7} optional />
                </fieldset>
            </fieldset>
            <fieldset>
                <legend>{$app.i18n.translates.EmailAccount}</legend>
                <TextInput id='txtEmailAccount' type='email' label={$app.i18n.translates.EmailAccount} value={model.accountUserName} onChanged={val => service.setModel('accountUserName', val ?? '')} tabIndex={8} />
                <TextInput id='txtEmailDisplayName' label={$app.i18n.translates.DisplayName} value={model.accountDisplayName} onChanged={val => service.setModel('accountDisplayName', val)} tabIndex={9} optional />
                <TextInput id='txtEmailAccountPassword' type='password' label={$app.i18n.translates.Password} value={model.accountPassword} onChanged={val => service.setModel('accountPassword', val ?? '')} tabIndex={10} />
            </fieldset>
        </TForm>
    </div>
}