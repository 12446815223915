import { ITreeService, TTreeOptions, TTreeNode, TTreeNodeInfo, TMoveNodeDto } from './tree.types';
import { ActionReducerMapBuilder, Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager } from "@app/core";
import { LTree } from '@dariosoft/framework';
import React from 'react';

const defaultOptions: TTreeOptions = {
    nodeSeparator: '.',
    collapseIcon: 'bi bi-dash-square',
    expandIcon: 'bi bi-plus-square',
    nodeDraggable: true
};

type TState = {
    treeId: string,
    loading: boolean,
    editingId: string | null,
    editingText: string | null,
    items: TTreeNode[]
};

type TDraft = Draft<TState>;

type TReducers = {
    setLoading: (state: TDraft, action: PayloadAction<boolean>) => void,
    deselectNode: (state: TDraft) => void,
    toggleExpand: (state: TDraft, action: PayloadAction<TTreeNode>) => void,
    appendNode: (state: TDraft, action: PayloadAction<TTreeNode>) => void,
    removeNode: (state: TDraft, action: PayloadAction<{ id: string }>) => void,
    selectNode: (state: TDraft, action: PayloadAction<TTreeNode>) => void,
    moveNode: (state: TDraft, action: PayloadAction<TMoveNodeDto>) => void,
    beginEdit: (state: TDraft, action: PayloadAction<TTreeNode>) => void,
    setEditingText: (state: TDraft, action: PayloadAction<string>) => void,
    acceptEdit: (state: TDraft) => void,
    cancelEdit: (state: TDraft) => void,
};

export type TTreeReducers<TS> = {
    [key: string]: (state: Draft<TS>, action: PayloadAction) => void
}

export abstract class TreeService<TS, TR extends TTreeReducers<TS>> implements ITreeService {
    protected constructor(treeId: string, state: TS, reducers: TR, private readonly treeOptions: TTreeOptions = {}) {
        if (String.isEmpty(treeId)) treeId = Guid.newId();
        this.treeOptions = { ...defaultOptions, ...treeOptions };
        const self = this;

        const [_slice, _getState] = StoreManager.createSlice<TS & TState, TReducers>({
            name: `tree-service/${treeId}`,
            initialState: {
                ...state,
                treeId: treeId,
                loading: false,
                editingId: null,
                editingText: null,
                items: []
            },
            reducers: {
                ...reducers,
                setLoading: (state: TDraft, action: PayloadAction<boolean>) => {
                    state.loading = action.payload;
                },
                deselectNode: (state: TDraft): void => {
                    state.items.forEach(n => n.isSelected = false);
                },
                toggleExpand: (state: TDraft, action: PayloadAction<TTreeNode>) => {
                    let node = state.items.find(n => n.id == action.payload.id);
                    if (node)
                        node.isExpanded = !action.payload.isExpanded;
                },
                appendNode: (state: TDraft, action: PayloadAction<TTreeNode>) => {
                    let parent = state.items.find(e => e.path == action.payload.parent);
                    if (!parent) return;
                    parent.isExpanded = true;
                    parent.hasChild = true;

                    if (action.payload.isSelected)
                        state.items.forEach(e => e.isSelected = false);

                    state.items.push(action.payload);
                },
                removeNode: (state: TDraft, action: PayloadAction<{ id: string }>) => {
                    let node = state.items.find(e => e.id == action.payload.id);
                    if (!node) return;
                    state.items.removeWhere(e => e.id == node.id || LTree.isAncestor(node.path, e.path));
                    state.items.forEach(e => e.isSelected = e.path == node.parent);
                    let parent = state.items.find(e => e.path == node.parent);
                    if (parent) {
                        parent.hasChild = state.items.some(e => e.parent == parent.path);
                    }
                },
                selectNode: (state: TDraft, action: PayloadAction<TTreeNode>) => {
                    state.items.forEach(n => n.isSelected = n.id == action.payload.id);
                },
                moveNode: (state: TDraft, action: PayloadAction<TMoveNodeDto>) => {
                    const sep: any = String.coalesce(this.treeOptions.nodeSeparator, '.')!,
                        src = state.items.find(e => e.id == action.payload.srcId),
                        dest = state.items.find(e => e.id == action.payload.destId);

                    if (!src || !dest) return;

                    let newPath = LTree.parse(LTree.changeParent(src.path, dest.path, sep));

                    let oldParentSegment = src.path + sep,
                        newParentSegment = newPath.value + sep;

                    state.items.filter(n => n.path.startsWith(oldParentSegment))
                        .forEach(n => {
                            let np = LTree.parse(n.path.replace(oldParentSegment, newParentSegment));
                            n.path = np.value;
                            n.parent = np.parent;
                            n.depth = np.depth;
                            n.level = np.level;
                            n.isRoot = np.level == 0;
                        });

                    let current = state.items.find(n => n.id == src.id);

                    if (current) {
                        current.path = newPath.value;
                        current.parent = newPath.parent;
                        current.depth = newPath.depth;
                        current.level = newPath.level;
                        current.isRoot = newPath.level == 0;
                    }

                    state.items.forEach(e => e.isSelected = e.id == src.id);
                    dest.hasChild = true;
                    dest.isExpanded = true;
                },
                beginEdit: (state: TDraft, action: PayloadAction<TTreeNode>) => {
                    state.items.forEach(n => n.isEditing = n.id == action.payload.id);
                    let node = state.items.find(n => n.isEditing);
                    if (node) {
                        state.editingId = node.id;
                        state.editingText = node.text;
                    }
                    else {
                        state.editingId = null;
                        state.editingText = null;
                    }
                },
                setEditingText: (state: TDraft, action: PayloadAction<string>) => {
                    state.editingText = String.isEmpty(state.editingId) || String.isEmpty(action.payload)
                        ? null
                        : action.payload;
                },
                acceptEdit: (state: TDraft) => {
                    let node = state.items.find(n => n.id == state.editingId);
                    if (node)
                        node.text = state.editingText || '';
                    state.editingId = null;
                    state.editingText = null;
                    state.items.forEach(n => n.isEditing = false);
                    state.loading = false;
                },
                cancelEdit: (state: TDraft) => {
                    state.editingId = null;
                    state.editingText = null;
                    state.items.forEach(n => n.isEditing = false);
                },
            },
            extraReducers: builder => {
                this.extraReducers(builder);
            }
            ,
            onStateChanged(currentState, previousState, action) {
                self.inspectSelectionChanged(currentState, previousState);

            },
        });

        this.slice = _slice;
        this.getState = _getState;

    }

    protected readonly slice!: Slice<TS & TState, TReducers>;
    protected readonly getState!: () => TState;
    protected readonly setLoading = (loading: boolean): void => StoreManager.dispatch(this.slice.actions.setLoading(loading));
    protected mapToNode<T>(nodes: T[], mapper: (e: T) => TTreeNodeInfo): TTreeNode[] {
        return this.infoToNode(nodes.map<TTreeNodeInfo>(mapper));
    }

    protected infoToNode(infos: TTreeNodeInfo[]): TTreeNode[] {
        let hasSelected = false;

        return infos.map<TTreeNode>((n, i, items) => {
            let ltree = LTree.parse(n.path, '.');
            hasSelected = hasSelected || Boolean(items[i - 1]?.isSelected);
            return {
                id: n.id,
                path: n.path,
                icon: n.icon,
                data: n.data,
                text: n.text,
                isExpanded: Boolean(n.isExpanded),
                isChecked: Boolean(n.isChecked),
                isSelected: Boolean(n.isSelected) && !hasSelected,
                isEditing: false,
                parent: ltree.parent,
                level: ltree.level,
                depth: ltree.depth,
                isRoot: n.isRoot,
                hasChild: items.some(e => ltree.isAncestor(e.path)),
                draggable: (this.treeOptions.nodeDraggable instanceof Function) ? this.treeOptions.nodeDraggable(n) : Boolean(this.treeOptions.nodeDraggable),
            }
        });
    }

    protected appendNode(info: TTreeNodeInfo): void {
        setTimeout(((node: TTreeNode) => StoreManager.dispatch(this.slice.actions.appendNode(node))).bind(this, this.infoToNode([info])[0]), 10);
    }

    protected removeNode(id: string): void {
        setTimeout(((nodeId: string) => StoreManager.dispatch(this.slice.actions.removeNode({ id: nodeId }))).bind(this, id), 10);
    }

    protected moveNode(e: TMoveNodeDto): void {
        setTimeout(((x: TMoveNodeDto) => StoreManager.dispatch(this.slice.actions.moveNode(x))).bind(this, e), 10);
    }

    protected endRename(accepted: boolean): void {
        if (accepted)
            setTimeout(() => StoreManager.dispatch(this.slice.actions.acceptEdit()), 10);
        else
            setTimeout(() => StoreManager.dispatch(this.slice.actions.cancelEdit()), 10);
    }

    protected abstract allowDragOverNode(e: React.DragEvent<HTMLElement>, node: TTreeNode): boolean;
    //protected abstract moveNode(src: TTreeNode, dest: TTreeNode): Promise<boolean>;

    //#region -- Virtual methods ---------
    protected onDropOver(e: React.DragEvent<HTMLElement>, target: TTreeNode): void {
        /*Virtual Method: Can be overridden*/
    }

    protected onNodeDropOver(e: React.DragEvent<HTMLElement>, source: TTreeNode, target: TTreeNode): void {
        /*Virtual Method: Can be overridden*/
    }

    protected requestRenameNode(node: TTreeNode, newInfo: { text: string }): void {
        /*Virtual Method: Can be overridden*/
    }
    protected extraReducers(builder: ActionReducerMapBuilder<TS & TState>): void {
        /*Virtual Method: Can be overridden*/
    }
    protected onClearSelection(): void { }
    protected onNodeSelected(node: TTreeNodeInfo, previousSelected?: TTreeNodeInfo): void { }
    //#endregion -- Virtual methods ---------

    public readonly getLoading = () => this.getState().loading;
    public readonly getTreeId = (): string => this.getState().treeId;
    public get collapseIcon(): string { return this.treeOptions.collapseIcon ?? ''; }
    public get expandIcon(): string { return this.treeOptions.expandIcon ?? ''; }
    public readonly getAllNodes = (): TTreeNode[] => this.getState().items;
    public readonly clearSelection = (): void => StoreManager.dispatch(this.slice.actions.deselectNode());
    public readonly selectNode = (node: TTreeNode) => StoreManager.dispatch(this.slice.actions.selectNode(node));
    public readonly getSelectedNode = (): TTreeNode | undefined => this.getState().items.find(e => e.isSelected);
    public readonly toggleExpand = (node: TTreeNode) => StoreManager.dispatch(this.slice.actions.toggleExpand(node));
    public readonly getRootNodes = (): TTreeNode[] => this.getState().items.filter(n => String.isEmpty(n.parent));
    public readonly getChildren = (parent: TTreeNode): TTreeNode[] => this.getState().items.filter(n => n.parent == parent.path);
    public readonly canDragOver = (e: React.DragEvent<HTMLElement>, sourceNodeId: string, targetNode: TTreeNode): boolean => {
        let state = this.getState();
        let srcNode = state.items.find(n => n.id == sourceNodeId);

        return (Boolean(srcNode) && !LTree.isAncestor(srcNode!.path, targetNode.path)) ||
            this.allowDragOverNode(e, targetNode);
    }
    public readonly onDrop = (e: React.DragEvent<HTMLElement>, sourceNodeId: string | null | undefined, targetNode: TTreeNode): void => {
        let state = this.getState();
        let srcNode = state.items.find(n => n.id == sourceNodeId);

        if (srcNode) {
            this.onNodeDropOver(e, srcNode, targetNode);
            // Move node:
            // this.moveNode(srcNode, targetNode).then(moved => {
            //     if (moved)
            //         StoreManager.dispatch(this.slice.actions.moveNode({ src: srcNode!, dest: targetNode! }));
            // });
        }
        else {
            // Move external elements:
            this.onDropOver(e, targetNode);
        }
    }

    public readonly editor = Object.freeze({
        //  isEnabled: (): boolean => Boolean(this.options.allowRename),
        // getEditingNodeId: (): string => this.getState().editingId ?? '',
        getEditingText: (): string => this.getState().editingText ?? '',
        setEditingText: (val: string): void => StoreManager.dispatch(this.slice.actions.setEditingText(val)),
        begin: (node: TTreeNode): boolean => {
            if (!this.canRenameNode(node)) return false;

            StoreManager.dispatch(this.slice.actions.beginEdit(node));
            return true;
        },
        accept: (): void => {
            let state = this.getState();
            let node = state.items.find(n => n.id == state.editingId);

            if (node && this.canRenameNode(node))
                this.requestRenameNode(node, { text: state.editingText ?? '' });
            else
                return this.editor.cancel();
        },
        cancel: (): void => StoreManager.dispatch(this.slice.actions.cancelEdit()),
    });

    private canRenameNode(node: TTreeNode): boolean {
        return this.treeOptions.allowRename instanceof Function
            ? this.treeOptions.allowRename(node)
            : Boolean(this.treeOptions.allowRename);
    }

    private inspectSelectionChanged(currState: TDraft, prvState: TDraft): void {

        let prv = prvState.items.find(e => e.isSelected);
        let curr = currState.items.find(e => e.isSelected);
        if (!curr)
            this.onClearSelection();
        else
            this.onNodeSelected(curr, prv);
    }


}