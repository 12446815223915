export const Icons = Object.freeze({
    add_Larg: 'bi bi-plus-lg',
    add: 'bi bi-plus',
    edit: 'bi bi-pencil',
    trash: 'bi bi-trash3',
    dash: 'bi bi-dash',
    credential: 'bi bi-shield-check',
    template: 'bi bi-file-text',
    refresh: 'bi bi-arrow-clockwise',
    home: 'bi bi-house-door',
    clients: 'bi bi-pc-display-horizontal',
    templateContext: 'bi bi-code-slash',
    preview: 'bi bi-eye',
    changePassword: 'bi bi-key',
    settings: 'bi bi-gear',
    signout: 'bi bi-box-arrow-right',
    userSettings: 'bi bi-person-gear',
    userCheck: 'bi bi-person-check',
    emailSettings: 'bi bi-envelope-at',
    forgotPasswordSettings: 'bi bi-shield-check',
    user: 'bi bi-person', //'bi bi-person-circle'
    folderClose: 'bi bi-folder2',
    folderOpen: 'bi bi-folder2-open',
    copy: 'bi bi-copy'
});