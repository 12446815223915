import './style.scss'
import { useSelector } from 'react-redux';
import { FieldView } from '../field-view';
import { TBsGridTierMap, TFieldValue } from '../common-types';
import React, { useMemo, useState } from 'react';

export type TSortOption = { field: string, label?: string };

export type TCardAction<T extends TUniqueItem> = {
    css?: string,
    text?: string,
    tooltip?: string,
    icon?: string,
    separator?: boolean;
    visible?: boolean | ((item: TCardItem<T>) => boolean),
    link?: string | ((item: TCardItem<T>) => string),
    linkTarget?: string,
    action?: (item: TCardItem<T>) => void
}

export type TCardItem<T extends TUniqueItem> = {
    id: string,
    data: T,
    params?: TPlainObject,
    header?: React.ReactNode,
    body?: {
        title?: React.ReactNode,
        subTitle?: React.ReactNode,
        description?: React.ReactNode,
    },
    list?: Array<TFieldValue | { label: string, value: TFieldValue }>,
    actions?: Array<TCardAction<T>>
}

export type TCardViewProps<T extends TUniqueItem> = {
    format: (item: T) => TCardItem<T>,
    context: TPagedListContext<T>,
    sortOptions?: Array<TSortOption>,
    searchBoxVisible?: boolean,
    extensions?: React.ReactNode,
    cssClass?: string,
    cardCssClass?: string | ((item: T) => string),
    rowCols?: TBsGridTierMap,
    gutter?: number,
    gutterX?: number,
    gutterY?: number,
    draggable?: boolean,
};

export const CardView = function <T extends TUniqueItem>(props: TCardViewProps<T>) {
    const rowCss = useMemo(() => getRowCss(props), [props.rowCols, props.gutter, props.gutterX, props.gutterY]),
        loading = useSelector(props.context.getLoading),
        items = useSelector(props.context.getItems).map(props.format);

    return <div className={`dariosoft-card-view ${loading ? 'loading-from ' : ''}${props.cssClass ?? ''}`.trim()}>
        <HeaderBar {...props} />
        <div className="item-container">
            {items.length > 0 && <div className={rowCss}>
                {items.map((x, i) => <Card key={`card-item-${i + 1}`} {...props} itemIndex={i} item={x} />)}
            </div>}
            {items.length == 0 && <div className='no-item'>
                <p>{$app.i18n.translates.NoRecords}</p>
            </div>}
        </div>
    </div>
}

const
    HeaderBar = function <T extends TUniqueItem>(props: { context: TPagedListContext<T>, sortOptions?: Array<TSortOption>, searchBoxVisible?: boolean, extensions?: React.ReactNode, }): JSX.Element {
        const
            searchBoxVisible = props.searchBoxVisible === undefined || Boolean(props.searchBoxVisible),
            hasSortOptions = props.sortOptions instanceof Array && props.sortOptions.length > 0,
            visible = searchBoxVisible || hasSortOptions || Boolean(props.extensions),
            val = useSelector(props.context.getTempQuery),
            loading = useSelector(props.context.getLoading),
            onKeyUp = (e: React.KeyboardEvent) => { (e.key.toUpperCase() === 'ENTER') && props.context.acceptQuery(); },
            onBlur = (e: React.FocusEvent) => props.context.acceptQuery();

        return visible ?
            <div className='header'>
                {searchBoxVisible && <input type='text'
                    value={val}
                    onChange={e => props.context.setTempQuery(e.target.value)}
                    onKeyUp={onKeyUp}
                    onBlur={onBlur}
                    disabled={loading}
                    placeholder={`${$app.i18n.translates.Search}...`}
                    className='srh' />}
                <SortOptions {...props} />
                {Boolean(props.extensions) && <div className='addons'>{props.extensions}</div>}
            </div>
            : <></>
    },
    SortOptions = function <T extends TUniqueItem>(props: { context: TPagedListContext<T>, sortOptions?: Array<TSortOption> }): JSX.Element {
        type TItem = { id: number, label: string, field: string, desc: boolean };

        const [selected, setSelected] = useState(0),
            deafultItem: TItem = { id: 0, label: $app.i18n.translates.DefaultSort, field: '', desc: false },
            hasSortOptions = isArray(props.sortOptions) && props.sortOptions!.length > 0,
            items = useMemo<Array<TItem>>(() => {
                if (!hasSortOptions) return [];
                let items = new Array((props.sortOptions!.length * 2) + 1);
                items[0] = deafultItem;
                props.sortOptions!.forEach((e, i) => {
                    let label = String.isEmpty(e.label) ? e.field : e.label, x = (i + 1) * 2;

                    items[x] = { id: x + 1, label: `${label} - ASC`, field: e.field, desc: false };
                    items[x + 1] = { id: x + 2, label: `${label} - DESC`, field: e.field, desc: true };
                });

                return items;

            }, [props.sortOptions]),
            onChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
                let val = Number.parse(e.target.value).controlMin(0);
                setSelected(val);
                let item = items.find(x => x && x.id == val);
                if (item) {
                    props.context.setSort(item.field, item.desc);
                }
            };

        return hasSortOptions
            ? <><select value={selected} onChange={onChanged}>
                {items.map((e, i) => <option value={e.id} key={`sort-option-${i}`}>{e.label}</option>)}
            </select>&nbsp;</>
            : <></>
    },
    Card = function <T extends TUniqueItem>(props: TCardViewProps<T> & { itemIndex: number, item: TCardItem<T> }) {
        const dragAttr = 'data-dragging'
            , [allowDrag, setAllowDrag] = useState(false)
            , onDragStart = (item: TCardItem<T>, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                e.currentTarget.classList.add('dragging');
                e.currentTarget.setAttribute(dragAttr, item.id);

            }
            , onDragEnd = (item: TCardItem<T>, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                setAllowDrag(false);
                e.currentTarget.classList.remove('dragging');
                e.currentTarget.removeAttribute(dragAttr);
            }

        return <div className='col'>
            <div draggable={props.draggable && allowDrag}
                onDragStart={props.draggable ? onDragStart.bind({}, props.item) : e => { e.preventDefault(); e.stopPropagation(); }}
                onDragEnd={props.draggable ? onDragEnd.bind({}, props.item) : undefined}
                
                className={`card ${props.cardCssClass instanceof Function ? props.cardCssClass(props.item.data) : props.cardCssClass ?? ''}`.trim()} >
                
                <CardHeader item={props.item} allowDrag={setAllowDrag} />
                <CardBody item={props.item} />
                <CardListItems item={props.item} />
                <CardFooter item={props.item} />
            </div>
        </div>
    },
    CardHeader = function <T extends TUniqueItem>(props: { item: TCardItem<T>, allowDrag: (allow: boolean) => void }): JSX.Element {
        return Boolean(props.item.header) ? <div className="card-header" onMouseDown={() => props.allowDrag(true) } onMouseUp={() => props.allowDrag(false) }>{props.item.header}</div> : <></>
    },
    CardBody = function <T extends TUniqueItem>(props: { item: TCardItem<T> }): JSX.Element {
        const hasTitle = Boolean(props.item.body?.title),
            hasSubtitle = Boolean(props.item.body?.subTitle),
            hasDescription = Boolean(props.item.body?.description),
            ok = Boolean(props.item.body) && (hasTitle || hasSubtitle || hasDescription);

        if (!ok) return <></>

        return <div className="card-body">
            {hasTitle && <h5 className="card-title">{props.item.body!.title}</h5>}
            {hasSubtitle && <h6 className="card-subtitle mb-2 text-muted">{props.item.body!.subTitle}</h6>}
            {hasDescription && <p className="card-text">{props.item.body!.description}</p>}
        </div>
    },
    CardListItems = function <T extends TUniqueItem>(props: { item: TCardItem<T> }): JSX.Element {
        if ((props.item.list?.length ?? 0) == 0) return <></>;
        const isFieldView = (e: any): boolean => isPlainObject(e) && Object.hasOwn(e, 'label') && Object.hasOwn(e, 'value');

        return <ul className="list-group list-group-flush">
            {props.item.list!.map((e, i) => <li key={`card-list-group-item-${props.item.id}-${i + 1}`} className="list-group-item">
                {isFieldView(e) && <FieldView label={(e as any).label} value={(e as any).value} />}
                {!isFieldView(e) && <>{e}</>}
            </li>)}
        </ul>
    },
    CardFooter = function <T extends TUniqueItem>(props: { item: TCardItem<T> }): JSX.Element {
        if ((props.item.actions?.length ?? 0) == 0) return <></>;
        const getLink = (e: TCardAction<T>): string => e.action instanceof Function ? '#' : (e.link instanceof Function ? e.link(props.item) : e.link ?? '#'),
            onClick = (e: TCardAction<T>) => e.action instanceof Function ? ((fn: (item: TCardItem<T>) => void, ev: React.MouseEvent) => { ev.preventDefault(); fn(props.item); }).bind({}, e.action) : undefined,
            visible = (e: TCardAction<T>): boolean => e.visible === undefined || e.visible === true || (e.visible instanceof Function && e.visible(props.item));

        return <div className="card-footer">
            {props.item.actions!.filter(visible).map((e, i) => <a
                key={`card-action-${props.item.id}-${i + 1}`}
                href={getLink(e)}
                target={e.linkTarget}
                onClick={onClick(e)}
                title={e.tooltip}
                className={`card-link ${e.css ?? ''}`.trim()}>
                {<i className={e.icon}>{e.text}</i>}
            </a>)}
        </div>
    },
    getRowCss = function <T extends TUniqueItem>(props: TCardViewProps<T>): string {
        let rowCols = getRowColsCssClasses(props.rowCols);
        let gutter = getGutterCssClasses(props);
        return `row row-cols-1 ${rowCols} ${gutter}`.trim();
    },
    getRowColsCssClasses = (input?: TBsGridTierMap): string => {
        let rowColsObj: any = input ?? {};
        let css = Object.keys(rowColsObj)
            .filter(key => rowColsObj[key] >= 0)
            .map(key => `row-cols-${key}-${rowColsObj[key]}`).join(' ');

        if (String.isEmpty(css)) css = 'row-cols-md-3 row-cols-xxl-4';

        return css;
    },
    getGutterCssClasses = (props: { gutter?: number, gutterX?: number, gutterY?: number }): string => {
        let gutter = props.gutter && props.gutter >= 0 ? `g-${props.gutter}` : '';
        gutter += props.gutterX && props.gutterX >= 0 ? ` gx-${props.gutterX}` : '';
        gutter += props.gutterY && props.gutterY >= 0 ? ` gy-${props.gutterY}` : '';
        gutter = gutter.trim();

        if (String.isEmpty(gutter)) gutter = 'g-3';
        return gutter;
    }