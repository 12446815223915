import { categoryTypes } from '@app/types';
import { ActionReducerMapBuilder, Draft } from '@reduxjs/toolkit';
import { ITreeService, MessageBox, TTreeNode, TTreeNodeInfo, TreeService } from '@dariosoft/components';
import * as api from '@app/api';
import React from 'react';
import { } from 'dariosoft/components/treeview/tree.service';


type TState = {
    clientId: string,
}

type TDraft = Draft<TState>;

type TReducers = {}


export class CategoryService extends TreeService<TState, TReducers> {
    constructor(private readonly options: {
        clientId: string,
        onSelectedChanged: (item: categoryTypes.TCategoryInfo) => void,
        onLoad: (currentSelected: categoryTypes.TCategoryInfo) => void,
        allowDragOverNode: (e: React.DragEvent<HTMLElement>, target: categoryTypes.TCategoryInfo) => boolean,
        onDropOver?: (e: React.DragEvent<HTMLElement>, target: categoryTypes.TCategoryInfo) => void
    }) {
        super(`category-service/${options.clientId}`, { clientId: options.clientId }, {}, {
            nodeSeparator: '.',
            allowRename: node => !node.isRoot
        });

    }

    protected override extraReducers(builder: ActionReducerMapBuilder<TState & { treeId: string; loading: boolean; editingId: string | null; editingText: string | null; items: TTreeNode[]; }>): void {
        builder.addCase(api.CategoryApi.instance.list.thunk.pending, state => { state.loading = true })
            .addCase(api.CategoryApi.instance.list.thunk.rejected, state => { state.loading = false })
            .addCase(api.CategoryApi.instance.list.thunk.fulfilled, (state, action) => {
                state.loading = false;

                if (action.payload.isSuccessful && action.payload.data) {
                    console.log('The category list has been loaded.');
                    state.items = this.mapToNode(action.payload.data, model => {
                        let isRoot = model.node.indexOf('.') < 0;
                        return {
                            id: model.id,
                            text: model.name,
                            path: model.node,
                            isRoot: isRoot,
                            isExpanded: isRoot,
                            isSelected: isRoot,
                            data: model
                        }
                    });

                    let selectedNode = state.items.find(e => e.isSelected)!;

                    setTimeout(((node: TTreeNodeInfo) => this.options.onLoad(node.data)).bind(this, selectedNode), 10);
                }
            })

            .addCase(api.CategoryApi.instance.append.thunk.pending, state => { state.loading = true })
            .addCase(api.CategoryApi.instance.append.thunk.rejected, state => { state.loading = false })
            .addCase(api.CategoryApi.instance.append.thunk.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.isSuccessful && action.payload.data) {
                    let model = {
                        id: action.payload.data.id,
                        serial: action.payload.data.serial,
                        clientId: action.meta.arg,
                        node: action.payload.data.node!,
                        isDefault: false,
                        name: `New Folder ${action.payload.data.serial}`,
                        description: undefined
                    } satisfies categoryTypes.TCategoryInfo;


                    super.appendNode({
                        id: model.id,
                        isRoot: model.node.indexOf('.') < 0,
                        path: model.node,
                        text: model.name,
                        isSelected: true,
                        data: model
                    });
                }
            })

            .addCase(api.CategoryApi.instance.permanentDelete.thunk.pending, state => { state.loading = true })
            .addCase(api.CategoryApi.instance.permanentDelete.thunk.rejected, state => { state.loading = false })
            .addCase(api.CategoryApi.instance.permanentDelete.thunk.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.isSuccessful) {
                    MessageBox.toast.deleteSuccessfull();
                    super.removeNode(action.meta.arg.data.id);
                }
            })

            .addCase(api.CategoryApi.instance.move.thunk.pending, state => { state.loading = true })
            .addCase(api.CategoryApi.instance.move.thunk.rejected, state => { state.loading = false })
            .addCase(api.CategoryApi.instance.move.thunk.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload.isSuccessful) {
                    MessageBox.toast.deleteSuccessfull();
                    super.moveNode(action.meta.arg);
                }
            })

            .addCase(api.CategoryApi.instance.update.thunk.pending, state => { state.loading = true })
            .addCase(api.CategoryApi.instance.update.thunk.rejected, state => { state.loading = false })
            .addCase(api.CategoryApi.instance.update.thunk.fulfilled, (state, action) => {
                state.loading = false;
                super.endRename(action.payload.isSuccessful);
                if (action.payload.isSuccessful) {
                    MessageBox.toast.deleteSuccessfull();
                }
            });
    }

    //#region Overrode methods
    protected override allowDragOverNode(e: React.DragEvent<HTMLElement>, node: TTreeNode): boolean {
        return this.options.allowDragOverNode(e, node.data);
    }

    protected override onNodeDropOver(e: React.DragEvent<HTMLElement>, source: TTreeNode, target: TTreeNode): void {
        api.CategoryApi.instance.move.submit({ srcId: source.id, destId: target.id });
    }

    protected override onDropOver(e: React.DragEvent<HTMLElement>, target: TTreeNode): void {
        if (this.options.onDropOver instanceof Function)
            this.options.onDropOver(e, target.data);
    }

    protected override requestRenameNode(node: TTreeNode, newInfo: { text: string; }): void {
        let model: categoryTypes.TCategoryInfo = node.data;

        api.CategoryApi.instance.update.submit({
            data: { ...model, name: newInfo.text },
            params: {}
        });
    }

    protected override onClearSelection(): void {
        this.options.onSelectedChanged(this.getRootNodes()[0].data);
    }

    protected override onNodeSelected(node: TTreeNodeInfo, previousSelected?: TTreeNodeInfo): void {
        this.options.onSelectedChanged(node.data);
    }
    //#endregion

    public addNew(e: React.MouseEvent): void {
        e.preventDefault();
        let parent = this.getSelectedNode() ?? this.getRootNodes()[0];
        api.CategoryApi.instance.append.submit(parent.id);
    }

    public remove(e: React.MouseEvent): void {
        e.preventDefault();
        let node = this.getSelectedNode();
        if (!node) return;
        if (node.data!.isDefault)
            return MessageBox.toast.warn($app.i18n.translates.MSG_UnableToDeleteRootFolder);

        MessageBox.modal.confirmDelete(node.text).then(accepted => {
            if (!accepted) return;

            api.CategoryApi.instance.permanentDelete.submit({
                data: node!.data,
                params: {}
            });
        });


    }


    public refresh(e: React.MouseEvent): void {
        e.preventDefault();
        this.load();
    }

    public readonly load = (): void => {
        api.CategoryApi.instance.list.submit({ data: { params: { clientId: this.options.clientId } }, params: {} });
    }
}