import { AuthService } from "@app/services";
import { TForm, TextInput } from "@dariosoft/components";
import { useSelector } from "react-redux";

export function Login(){
    const service = AuthService.instance.login
     , model = useSelector(service.getModel)
     , loading = useSelector(AuthService.instance.getLoading);

    const otherButtons = <>
        <a href="#/auth/forgot-password">{$app.i18n.translates.Forgot_Password_Question}</a>
    </>

    return <div className="vw-login">
        <TForm id="from-login" loading={loading} onSubmit={service.submit} submitText={$app.i18n.translates.Login} lastTabIndex={2} otherButtons={otherButtons}>
            <TextInput label={$app.i18n.translates.UserName} value={model.username} onChanged={service.setUserName} id="txtUserName" autoFocus tabIndex={1}/>
            <TextInput label={$app.i18n.translates.Password} value={model.password} onChanged={service.setPassword} id="txtPassword" type="password" tabIndex={2}/>
        </TForm>
    </div>
}