export type TCulture = { name: string, culture: string, title: string, rtl: boolean }

declare global {

  const $app: {
    i18n: {
      cultures: Array<TCulture>,
      getLangName: () => string,
      getCurrent: () => TCulture,
      translates: { [key: string]: string },
      enumTranslates: { [key: string]: string },
      buildUrl: (e: TCulture) => string
    },
    settings: {
      apiUrl: string
    },
    loginInfo?: {
      username: string,
      password: string
    }
  }
}

$app.i18n.buildUrl = (e: TCulture): string => {
  let url = new URL(window.location.href);
  return `${url.origin}?lang=${e.name}${url.hash}`;
}