import './style.scss';
import React, { useContext, useMemo } from 'react';
import { ITreeService, TTreeNode } from './tree.types'
import { useSelector } from 'react-redux';

export * from './tree.types';
export { TreeService } from './tree.service';

const ServiceContext = React.createContext<ITreeService | undefined>(undefined);

export function TreeView(props: { service: ITreeService, css?: string, header?: JSX.Element }) {
    const treeId = useSelector(props.service.getTreeId)
        , rootNodes = useSelector(props.service.getRootNodes)
        , loading = useSelector(props.service.getLoading)
        , generateChild = (child: TTreeNode, level: number): JSX.Element => {
            let children = props.service.getChildren(child);
            if (children.length == 0) return <></>;

            return <ul className={`nested ${child.isExpanded ? 'expanded' : ''}`} data-level={level}>
                {children.map((n, i) => <TreeNode
                    key={`level-${level}-child-node-${i}`}
                    level={0}
                    node={n}
                    generateChild={generateChild}
                />)}
            </ul>
        };

    return <ServiceContext.Provider value={props.service}>
        <div className={`treeview ${loading ? 'loading-from' : ''} ${props.css ?? ''}`.trim()} data-tree-id={treeId} id={treeId}>
            {props.header && <header>{props.header}</header>}
            <ul data-level="0" onClick={props.service.clearSelection.bind(props.service)}>
                {rootNodes.map((n, i) => <TreeNode
                    key={`root-not-${i}`}
                    level={0}
                    node={n}
                    generateChild={generateChild}
                />)}
            </ul>
        </div>
    </ServiceContext.Provider>

}

const
    TreeNode = function (props: { level: number, node: TTreeNode, generateChild: (node: TTreeNode, level: number) => JSX.Element }) {
        const service = useContext(ServiceContext)!,
            dragAttr = 'ariosoft-treenode-dragging', dragSelector = `li[${dragAttr}]`,
            onDragStart = (node: TTreeNode, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                e.currentTarget.setAttribute(dragAttr, node.id);
            },
            onDragEnd = (node: TTreeNode, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                e.currentTarget.removeAttribute(dragAttr);
            },
            onDragOver = (node: TTreeNode, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                e.preventDefault();

                let src = e.currentTarget
                    .closest('.treeview')!
                    .querySelector<HTMLElement>(dragSelector);

                e.dataTransfer.dropEffect = service.canDragOver(e, src?.getAttribute(dragAttr) ?? '', node)
                    ? 'move'
                    : 'none';
            },
            onDrop = (node: TTreeNode, e: React.DragEvent<HTMLElement>) => {
                e.stopPropagation();
                e.preventDefault();

                let srcId = e.currentTarget.closest('.treeview')!.querySelector<HTMLElement>(dragSelector)?.getAttribute(dragAttr);
                service.onDrop(e, srcId, node);
            }



        return <li data-id={props.node.id}
            draggable={props.node.draggable}
            onDragStart={props.node.draggable ? onDragStart.bind({}, props.node) : e => { e.preventDefault(); e.stopPropagation() }}
            onDragOver={props.node.draggable ? onDragOver.bind({}, props.node) : undefined}
            onDrop={props.node.draggable ? onDrop.bind({}, props.node) : undefined}
            onDragEnd={props.node.draggable ? onDragEnd.bind({}, props.node) : undefined}
            className={`tree-node ${props.node.isSelected ? 'selected' : ''}`.trim()}>
            <div className='actions'>
                {props.node.hasChild && <ExpandButton node={props.node} />}
                <NodeElement node={props.node} />
            </div>
            {props.generateChild(props.node, props.level + 1)}
        </li>
    },
    ExpandButton = function (props: { node: TTreeNode }) {
        const service = useContext(ServiceContext)!,
            toggleExpand = (node: TTreeNode, e: React.MouseEvent<HTMLSpanElement>) => {
                e.stopPropagation();
                service.toggleExpand(node);
            }

        return <span onClick={toggleExpand.bind({}, props.node)} className="status-icon closed indicator"><i className={props.node.isExpanded ? service.collapseIcon : service.expandIcon}></i></span>
    },


    NodeElement = function (props: { node: TTreeNode }) {
        const service = useContext(ServiceContext)!,
            editingNodeText = useSelector(service.editor.getEditingText),
            editInputStyle = useMemo(() => ({ width: editingNodeText.length < 20 ? `${editingNodeText.length + 2}ch` : `20ch` }), [editingNodeText]),
            onClick = (node: TTreeNode, e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                e.stopPropagation();
                service.selectNode(node);
            },
            onKeyUp = (node: TTreeNode, e: React.KeyboardEvent<HTMLElement>) => {
                e.key = (e.key ?? '').toUpperCase();
                switch ((e.key ?? '').toUpperCase()) {
                    case 'ENTER':
                        if (node.isEditing)
                            service.editor.accept();
                        break;
                    case 'ESCAPE':
                        if (node.isEditing)
                            service.editor.cancel();
                        break;
                    case 'F2':
                        if (service.editor.begin(node)) {
                            setTimeout(() => $(e.target).find('input[type=text]').trigger('focus'), 50);
                        }
                        break;
                }
            }

        return <a href="#"
            onKeyUp={onKeyUp.bind({}, props.node)}
            onClick={onClick.bind({}, props.node)}
            className="node-item">
            {!String.isEmpty(props.node.icon) && <i className={props.node.icon}></i>}
            <span className="txt">
                {props.node.isEditing && <input type='text' style={editInputStyle} value={editingNodeText} onChange={e => service.editor.setEditingText(e.target.value)} onBlur={e => service.editor.cancel()} />}
                {!props.node.isEditing && <>{props.node.text}</>}
            </span>
        </a>
    }

