import './style.scss';

type TType = number | string;

export type TSelectItem<T extends TType> = { text: string, value: T, data?: any };
type AsyncProvider<T extends TType> = () => Promise<TSelectItem<T>[]>;
type Provider<T extends TType> = () => TSelectItem<T>[];

type TSelectCommonProps = {
    id: string,
    label?: string,
    description?: string,
    tabIndex?: number,
    autoFocus?: boolean,
    disabled?: boolean,
    optional?: boolean,
    showUnknownItem?: boolean,
}

export type TNumericSelectProps = TSelectCommonProps & {
    value?: number | null,
    items: TSelectItem<number>[] | AsyncProvider<number> | Provider<number>,
    onChanged?: (value?: number, e?: React.ChangeEvent<HTMLSelectElement>) => void
}

export type TStringSelectProps =TSelectCommonProps & {
    value?: string | null,
    items: TSelectItem<string>[] | AsyncProvider<string> | Provider<string>,
    onChanged?: (value?: string, e?: React.ChangeEvent<HTMLSelectElement>) => void
}

export function NumSelect(props: TNumericSelectProps) {
    return Select({ ...props, type: 'number' } as TSelectProps);
}

export function StrSelect(props: TStringSelectProps) {
    return Select({ ...props, type: 'string' } as TSelectProps);
}

type TSelectProps = TSelectCommonProps & {
    type?: 'string' | 'number',
    value?: TType,
    items: TSelectItem<TType>[],
    onChanged?: (value?: string | number | null, e?: React.ChangeEvent<HTMLSelectElement>) => void
};

function Select(props: TSelectProps) {
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (props.onChanged && props.onChanged instanceof Function) {
            let value: any = e.target.value;
            if (props.type == 'number') {
                value = +value;
                value = isNaN(value) ? 0 : value;
            }

            props.onChanged(value, e)
        }
    }

    const onInput = (e: React.FormEvent<HTMLSelectElement>) => {
        (e.target as HTMLSelectElement).setCustomValidity('');
    }

    const onInvalid = (e: React.FormEvent<HTMLSelectElement>) => {
        (e.target as HTMLSelectElement).setCustomValidity($app.i18n.translates.MSG_Warn_FillOutField);
    }

    return <div className="mb-2 form-input-element select-input">
        {!String.isEmpty(props.label) && <label htmlFor={props.id} className="form-label" aria-required={!props.optional}>{props.label}</label>}
        {!String.isEmpty(props.description) && <p>{props.description}</p>}
        <select id={props.id}
            name={props.id}
            value={props.value ?? ''}
            onInvalid={onInvalid}
            onInput={onInput}
            required={!Boolean(props.optional)}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
            autoFocus={props.autoFocus}
            onChange={onChange}
            className="form-select">
            {props.showUnknownItem && <option value=''>{$app.i18n.translates.SelectAnItem}</option>}
            {props.items.map((e, i) => <option value={e.value} key={`select-input-${props.id}-${i + 1}`}>{e.text}</option>)}
        </select>
    </div>
}