import { Draft, PayloadAction, Slice } from "@reduxjs/toolkit";
import { StoreManager } from "../store-manager";

type TState = {
    loading: boolean,
    sidebarVisible: boolean,
    sidbarWidth: string,
    contentWidth: string,
}

type TDraft = Draft<TState>;
type TReducers = {
    setSidebarVisible: (state: Draft<TState>, action: PayloadAction<boolean>) => void
};

export class MainLayoutService {
    private constructor() {
        let store = StoreManager.createSlice<TState, TReducers>({
            name: 'services/main-layout-service',
            initialState: {
                loading: false,
                sidebarVisible: true,
                sidbarWidth: '12rem',
                contentWidth: 'calc(100% - 12rem)'
            },
            reducers: {
                setSidebarVisible: (state: Draft<TState>, action: PayloadAction<boolean>) => {
                    state.sidebarVisible = action.payload;
                    state.contentWidth = state.sidebarVisible ? `calc(100% - ${state.sidbarWidth})` : `100%`
                }
            },
            extraReducers: (builder) => {

            }
        });

        this.slice = store[0];
        this.getState = store[1];
    }

    //#region  PUBLICS
    public static get instance(): MainLayoutService {
        if (MainLayoutService._instance == null) MainLayoutService._instance = new MainLayoutService();
        return MainLayoutService._instance!;
    }
    public getLoading = () => this.getState().loading;
    public setSidebarVisible = (visible: boolean) => StoreManager.dispatch(this.slice.actions.setSidebarVisible(visible));
    public getSidebarVisible = () => this.getState().sidebarVisible;
    public getSidebarWidth = () => this.getState().sidbarWidth;
    public getContentWidth = () => this.getState().contentWidth;
    //#endregion

    //#region  PRIVATES
    private static _instance: MainLayoutService | null = null;
    private readonly getState!: () => TState;
    private readonly slice!: Slice<TDraft, TReducers>;
    //#endregion
}