import logo from '@app/assets/images/logo.png';
import './style.scss';
import { AuthNavBar } from './navbar';
import { Outlet, useMatches } from 'react-router-dom';
import { AuthBanner } from './banner';

export function AuthLayout() {
    const matches = useMatches()
        , getTitle = () => {
            let match = matches[matches.length - 1];
            return Boolean(match) ? (match.handle as any).title : $app.i18n.translates.Login;
        };


    return <div className="layout layout-auth container-fluid">
        <AuthNavBar />
        <div className="frame row">
            <AuthBanner title='TEMPLATE ADMIN' logo={logo} link='https://dariosoft.org' />
            <div className="auth-layout-content col-8">
                <h4 className='title'>{getTitle()}</h4>
                <Outlet />
            </div>
        </div>
    </div>
}