import { TemplateContextService } from "@app/services";
import { CodeEditor, FieldView, TForm, TextInput } from "@dariosoft/components";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { json } from '@codemirror/lang-json';
import { html } from '@codemirror/lang-html';
import { quietlight as theme } from '@uiw/codemirror-theme-quietlight';

export function TemplateContext() {
    const { clientId, templateId } = useParams();

    return <div className="vw vw-template-editor">
        <TemplateContextEditor clientId={clientId ?? ''} templateId={templateId ?? ''} />
    </div>
}

export function TemplateContextEditor(props: { clientId: string, templateId: string }) {
    const service = useMemo(() => new TemplateContextService(props.templateId), [props.templateId])
        , loading = useSelector(service.getLoading)
        , info = useSelector(service.getInfo)
        , model = useSelector(service.getModel)
        , previewUrl = useMemo(service.getPreviewUrl, [props.templateId])
        , style = {
            width: '100%'
        }
        , mailAddress = useSelector(service.mailTo.getMail)
        , otherButtons = <>
            <a href={previewUrl} target="_blank" className="btn btn-sm btn-primary">{$app.i18n.translates.Preview}</a>
        </>;

    useEffect(() => {
        service.load();
    }, []);

    return <div className="template-context-editor" style={style}>
        <fieldset className="mb-2">
            <legend>{$app.i18n.translates.Information}</legend>
            <FieldView label={$app.i18n.translates.Name} value={info.name} />
            <FieldView label={$app.i18n.translates.Path} value={info.path} />
            <FieldView label={$app.i18n.translates.Description} value={info.description} />
        </fieldset>
        <TForm id="formTemplateContext" onSubmit={service.save} loading={loading} lastTabIndex={3} otherButtons={otherButtons}>
            <CodeEditor id="txtDataModel" syntax={json()} theme={theme} label={$app.i18n.translates.DataModel} description={$app.i18n.translates.Template_Json_DataModel_Description} value={model.dataModel} onChanged={text => service.setModel('dataModel', text)} tabIndex={1} optional autoFocus />
            <CodeEditor id="txtContent" syntax={html()} theme={theme} label={$app.i18n.translates.Content} description={$app.i18n.translates.Template_Content_Description} value={model.content} onChanged={text => service.setModel('content', text)} tabIndex={3} />
        </TForm>
        <div className="mt-2">
            <TForm id="formMailTo" onSubmit={service.mailTo.submit} loading={loading} submitText={$app.i18n.translates.Send}>
                <TextInput id="txtMailTo" type="email" value={mailAddress} onChanged={val => service.mailTo.setMail(val ?? '')} label={$app.i18n.translates.MailTo} description={$app.i18n.translates.MSG_MailTo_Description} />
            </TForm>
        </div>
    </div>
}