import {
    EnhancedStore,
    Action,
    configureStore,
    CreateSliceOptions,
    Slice,
    SliceCaseReducers,
    combineReducers,
    createSlice,
    UnknownAction,
} from "@reduxjs/toolkit";


export type TSharedState = { loading: boolean, test: string };
export type TSharedReducer = SliceCaseReducers<TSharedState>;
export type TSliceCreateOptions<TState, TReducers extends SliceCaseReducers<TState>> = CreateSliceOptions<TState, TReducers> & {
    onStateChanging?: (state: TState, action: { type: string, payload: any }) => undefined | false,
    onStateChanged?: (currentState: TState, previousState: TState, action: { type: string, payload: any }) => void
}

export interface IStoreManager {
    get store(): EnhancedStore;
    dispatch: (thunkAction: UnknownAction) => void,
    createSlice: <TState, TReducers extends SliceCaseReducers<TState>>(options: TSliceCreateOptions<TState, TReducers>, dropIfExists?: boolean) => [Slice<TState, TReducers>, () => TState],
    removeSlice: (name: string) => void,
    setLoading: (loading: boolean) => void
}

export const initStore = function (): IStoreManager {
    const inspectors: {
        [key: string]: {
            onBeforeAffect: undefined | null | ((state: any, action: any) => undefined | false),
            onAfterAffect: undefined | null | ((currentState: any, newState: any, action: any) => void),
        }
    } = {},
        myThunkMiddleware = (store: any) => (next: any) => (action: any) => {
            // action => { type: string, payload?: any }
            let actionName: string = action.type;
            let sliceName = actionName.substring(0, actionName.lastIndexOf('/'));
            let inspector = inspectors[sliceName];
            let prevState = store.getState()[sliceName];
            if (inspector && inspector.onBeforeAffect instanceof Function) {

                if (inspector.onBeforeAffect(prevState, action) === false)
                    return;
            }

            let result = next(action);
            if (inspector && inspector.onAfterAffect instanceof Function) {
                let currState = store.getState()[sliceName];
                inspector.onAfterAffect(currState, prevState, action);
            }

            return result;
        };

    const sliceCollection: { [key: string]: any } = {},
        registeredReducers: { [key: string]: any } = {},
        store = configureStore({
            // preloadedState: {},
            reducer: {
                default: (state = {}) => state
            },
            middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(myThunkMiddleware)
        }),


        context: any = {
            dispatch: (thunkAction: Action) => store.dispatch(thunkAction),
            createSlice: <TState, TReducers extends SliceCaseReducers<TState>>(
                options: TSliceCreateOptions<TState, TReducers>,
                dropIfExists?: boolean
            ): [Slice<TState, TReducers>, () => TState] => {
                if (!/^[a-z][/-_a-z-0-9]*$/i.test(options.name))
                    throw new Error(`Invalid service name.\r\nThe name "${options.name}" is not valid.\r\nThe service name must starts with letters(i.e, a-z) followed by 0-9, a-z, hyphen, underscroe or slash.\r\nExamples: services/user-service\r\n******************************************************************************************`);

                let invalidActionName = Object.keys(options.reducers).find(name => !/^[a-z][a-z0-9]*$/i.test(name));
                if (!String.isEmpty(invalidActionName))
                    throw new Error(`The reducer name "${invalidActionName}" is not valid. Only letters and digits are valid and the name must starts with a letter as well.\r\n****************************************************************************************`);

                if (sliceCollection[options.name]) {
                    if (dropIfExists)
                        context.removeSlice(options.name);
                    else
                        return sliceCollection[options.name];
                }
                //if (registeredReducers[options.name])
                // throw new Error(`A slice is already has registered with the name "${options.name}".`, { cause: 'DuplicateName' });


                inspectors[options.name] =
                {
                    onBeforeAffect: options.onStateChanging,
                    onAfterAffect: options.onStateChanged
                };

                let slice = createSlice(options);



                registeredReducers[slice.name] = slice.reducer;

                let updatedRootReducer: any = combineReducers(registeredReducers);

                store.replaceReducer(updatedRootReducer);

                var state = store.getState();

                sliceCollection[options.name] = [
                    slice,
                    ((sliceName: string) => (store.getState() as any)[sliceName]).bind({}, options.name),
                ];

                return sliceCollection[options.name];
            },
            removeSlice: (name: string) => {
                if (!registeredReducers[name]) return;

                delete registeredReducers[name];
                delete sliceCollection[name];

                let updatedRootReducer: any = combineReducers(registeredReducers);

                store.replaceReducer(updatedRootReducer);

                console.warn(`The store slice "${name}" has been removed.`);
            },
        };


    return Object.defineProperties<IStoreManager>(context, {
        store: { get: () => store }
    });
}